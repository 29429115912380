import React, { useEffect } from "react"
import { Address } from "everscale-inpage-provider"
import {
  ChevronDownIcon,
  CopyIcon,
  LogOutIcon,
  PackageOpenIcon,
  PlusIcon,
} from "lucide-react"
import { useCopyToClipboard } from "usehooks-ts"

import { Account } from "@/types/venom"

import { getAvatarUrlByAddress } from "@/utils/profile-utils"
import { cn } from "@/utils/tw-utils"
import { shortenAddress } from "@/utils/venom-utils"
import { packageCollectionContractConfig } from "@/config/contracts-config"
import { useUi } from "@/providers/ui-provider"
import { useVenom } from "@/providers/venom-provider"
import { useBalance } from "@/hooks/venom/use-balance"
import { useOwnedCollectionNfts } from "@/hooks/venom/use-owned-collection-nfts"
import { Avatar, AvatarImage } from "@/components/ui/avatar"
import { Balance } from "@/components/ui/balance"
import { Button } from "@/components/ui/button"
import { Menu, MenuButton } from "@/components/ui/menu"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Separator } from "@/components/ui/separator"
import { Spinner } from "@/components/ui/spinner"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

type ConnectProps = Omit<React.ComponentPropsWithoutRef<"div">, "children"> & {
  _popoverContent?: React.ComponentPropsWithRef<typeof PopoverContent>
  _profileButton?: Omit<React.ComponentPropsWithRef<typeof Button>, "onClick">
  _connectButtonWrapper?: React.ComponentPropsWithRef<"div">
  onConnectAttempt?: () => void
  onDisconnectAttempt?: () => void
}

const Connect = ({
  _popoverContent = {},
  _profileButton = {},
  _connectButtonWrapper = {},
  className,
  ...props
}: ConnectProps) => {
  const { account, connect, disconnect } = useVenom()
  const { balance } = useBalance()

  const { data: packages } = useOwnedCollectionNfts({
    walletAddress: account?.address,
    collectionAddress: packageCollectionContractConfig.address,
  })

  const { className: popoverContentClassName, ..._popoverContentProps } =
    _popoverContent

  let children: React.ReactNode | null = null

  if (account) {
    children = (
      <Popover>
        <PopoverTrigger asChild>
          <AccountMenuTrigger account={account} {..._profileButton} />
        </PopoverTrigger>
        <PopoverContent
          className={cn("w-[13.25rem] p-3", popoverContentClassName)}
          {..._popoverContentProps}
        >
          <div className="flex flex-col gap-1">
            <CopyableAddress address={account.address} />
            <AccountBalance balance={balance ?? "-"} />
          </div>

          <Separator className="my-3" />

          <ProfileMenu
            packagesCount={packages?.length ?? 0}
            onDisconnect={disconnect}
          />
        </PopoverContent>
      </Popover>
    )
  } else if (account) {
    children = (
      <div className="flex h-full w-full items-center justify-center">
        <div className="flex h-10 w-10 items-center justify-center">
          <Spinner />
        </div>
      </div>
    )
  } else {
    children = (
      <div {..._connectButtonWrapper}>
        <Button variant="outline" className="w-full" onClick={connect}>
          Connect wallet
        </Button>
      </div>
    )
  }

  return (
    <div className={cn("flex items-center", className)} {...props}>
      {children}
    </div>
  )
}

type AccountMenuTriggerProps = React.ComponentPropsWithoutRef<typeof Button> & {
  account: Account
}

const AccountMenuTrigger = React.forwardRef<
  HTMLButtonElement,
  AccountMenuTriggerProps
>(({ account, className, ...props }, ref) => {
  return (
    <Button
      variant="ghost"
      className={cn("group h-11 rounded-full pl-2 pr-3 font-normal", className)}
      ref={ref}
      {...props}
    >
      <Avatar className="mr-2 h-7 w-7">
        <AvatarImage src={getAvatarUrlByAddress(account.address)} alt="" />
      </Avatar>

      <span className="inline-flex flex-col items-start justify-start gap-0.5">
        <span className="block w-28 text-left text-base font-bold leading-tight">
          {shortenAddress(account.address)}
        </span>
      </span>

      <ChevronDownIcon
        className="relative top-[1px] ml-auto h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
        aria-hidden="true"
      />
    </Button>
  )
})
AccountMenuTrigger.displayName = "AccountMenuTrigger"

const AccountBalance = ({ balance }: { balance: string }) => {
  return (
    <div className="flex w-full items-center justify-between gap-2 pl-3 pr-0">
      <Balance className="text-base font-normal" value={balance} />

      <Button
        variant="ghost"
        size="icon"
        className="h-10 w-10 text-foreground/60"
        asChild
      >
        <a href="https://venom.network" target="_blank" rel="noreferrer">
          <PlusIcon className="h-4 w-4" />
          <span className="sr-only">Copy address</span>
        </a>
      </Button>
    </div>
  )
}
AccountBalance.displayName = "AccountBalance"

const CopyableAddress = ({ address }: { address: Address | string }) => {
  const [, copy] = useCopyToClipboard()
  const [copied, setCopied] = React.useState(false)
  const timeoutIdRef = React.useRef<number>()

  const handleCopy = () => {
    copy(address.toString())

    setCopied(true)

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
    }

    timeoutIdRef.current = window.setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current)
      }
    }
  }, [])

  return (
    <div className="flex w-full items-center justify-between gap-2 pl-3 pr-0">
      <div className="text-base font-normal">{shortenAddress(address, 8)}</div>

      <Tooltip open={copied}>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="h-10 w-10 text-foreground/60"
            onClick={handleCopy}
          >
            <CopyIcon className="h-4 w-4" />
            <span className="sr-only">Copy address</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Copied!</TooltipContent>
      </Tooltip>
    </div>
  )
}
CopyableAddress.displayName = "CopyableAddress"

const ProfileMenu = ({
  packagesCount,
  onDisconnect,
}: {
  packagesCount?: number
  onDisconnect: () => void
}) => {
  const { toggleUiPackagesDialog } = useUi()

  return (
    <Menu className="flex-col items-start justify-start">
      <MenuButton
        title="My packages"
        badge={packagesCount}
        icon={PackageOpenIcon}
        className="w-full justify-start px-3 font-normal"
        onClick={() => toggleUiPackagesDialog(true)}
      />

      <MenuButton
        title="Disconnect"
        icon={LogOutIcon}
        className="w-full justify-start px-3 font-normal"
        onClick={onDisconnect}
      />
    </Menu>
  )
}
ProfileMenu.displayName = "ProfileMenu"

export type { ConnectProps }
export { Connect }
